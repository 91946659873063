import { store } from '../store/storeMain'
import { globalFunctions } from './globalFunctions'
import { accountManager } from './accountManager'

export const transactionManager = {

  transactions: [],

  transactionObject: {
    id: 0,
    account: '',
    transactionName: '',
    transactionType: '',
    //transactionAmount: 0,
    //paymentType: '',
    budget: 'None',
    comment: '',
    category: '',
    currency: '',
    //items: [],

    //transactionDate: '',
    //transactionTime: null,

    transferFrom: '',
    transferTo: '',

    transactionBalances: [],

    recurringTransaction: false,

    //Recurring Transactions
    repeat: 1,
    cycle: '',
    period: 'Forever',
    numberOfCycles: 1,
    goesOffFirst: '',

    //State of Current Object
    objectState: 'New',
    show: false
   },
    transactionBalances: {
        id: 0,
        transactionDate: '',
        transactionTime: null,
        transactionDiscount: 0,
        transactionAmount: 0,
        paymentType: '',
        foreignCurrencyAmount: 0,
        transactionItems: [],
        changed: false,
    },

  getTransactions() {
    this.transactions = store.state.Transactions
  },

  addTransaction(transaction) {
    let newEntry = globalFunctions.stringify(transaction)

    this.addItem = false

    this.getTransactions()

    newEntry.id = globalFunctions.getNextIdForObject(this.transactions)

    //newEntry = this.formatTransactionObjectForApi(newEntry)

    this.addTransactionToStore(newEntry)

    this.adjustAccountWithTransactionAmount(transaction)
  },

    formattransactionBalanceForApi(transaction) {
        let balance = this.transactionBalances

        //for (let i = 0; i < transaction.transactionBalances.length; i++) {
        balance.id = transaction.id
        balance.transactionDate = transaction.transactionDate
        balance.transactionTime = transaction.transactionTime
        balance.transactionDiscount = parseFloat(transaction.transactionDiscount)
        balance.transactionAmount = parseFloat(transaction.transactionAmount)
        balance.paymentType = transaction.paymentType
        balance.foreignCurrencyAmount = parseFloat(transaction.foreignCurrencyAmount)
        balance.transactionItems = transaction.transactionItems
        //}

        return balance
    },

  addTransactionToStore(newEntry) {
    store.commit('addNewTransactionToStore', newEntry)
  },

  adjustAccountWithTransactionAmount(currentTransaction) {
    let add = {
      account: '',
      amount: ''
    }
    let subtract = {
      account: '',
      amount: ''
    }

    if (currentTransaction.transactionType === 'income') {
      add.account = currentTransaction.account
      add.amount = currentTransaction.transactionBalances[0].transactionAmount

        accountManager.addAmountToAccount(add)
    }
    else if (currentTransaction.transactionType === 'expense') {
      subtract.account = currentTransaction.account
      subtract.amount = currentTransaction.transactionBalances[0].transactionAmount

        accountManager.subtractAmountFromAccount(subtract)
    }
    else if (currentTransaction.transactionType === 'transfer') {
      subtract.account = currentTransaction.transferFrom
      subtract.amount = currentTransaction.transactionBalances[0].transactionAmount

        accountManager.subtractAmountFromAccount(subtract)

      add.account = currentTransaction.transferTo
      add.amount = currentTransaction.transactionBalances[0].transactionAmount

        accountManager.addAmountToAccount(add)
    }

      //if (currentTransaction.transactionType === 'transfer') {
      //    let accountFrom = accountManager.getAccountByName(currentTransaction.transferFrom)
      //    store.commit('updateAccountinStore', accountFrom)

      //    let accountTo = accountManager.getAccountByName(currentTransaction.transferTo)
      //    store.commit('updateAccountinStore', accountTo)

      //    return
      //}

      //let accountObject = accountManager.getAccountByName(currentTransaction.account)
      //store.commit('updateAccountinStore', accountObject)
  },

  updateAccountWithChangedTransactionAmount(account, transferTo, oldAmount, newAmount, transactionType) {
    let tranObject = {
      account: '',
      amount: ''
    }

    tranObject.account = account

    if (transactionType === 'expense') {
      if (oldAmount > newAmount) {
        //Add Remainder from account
        tranObject.amount = parseFloat(oldAmount) - parseFloat(newAmount)

          accountManager.addAmountToAccount(tranObject)
      }
      else if (newAmount > oldAmount) {
        //Subtract Remainder to account
        tranObject.amount = parseFloat(newAmount) - parseFloat(oldAmount)

          accountManager.subtractAmountFromAccount(tranObject)
      }
    }
    else if (transactionType === 'income') {
      if (oldAmount > newAmount) {
        //Subtract Remainder from account
        tranObject.amount = parseFloat(oldAmount) - parseFloat(newAmount)

          accountManager.subtractAmountFromAccount(tranObject)
      }
      else if (newAmount > oldAmount) {
        //Add Remainder to account
        tranObject.amount = parseFloat(newAmount) - parseFloat(oldAmount)

          accountManager.addAmountToAccount(tranObject)
      }
    }
    else if (transferTo !== '') {
      if (oldAmount > newAmount) {
        //Subtract amount from transferTo Account
        tranObject.amount = parseFloat(oldAmount) - parseFloat(newAmount)

          accountManager.subtractAmountFromAccount({ account: transferTo, amount: tranObject.amount })

        //Add amount to transferFrom account
          accountManager.addAmountToAccount(tranObject)
      }
      else if (newAmount > oldAmount) {
        //Subtract amount from transferFrom Account
        tranObject.amount = parseFloat(newAmount) - parseFloat(oldAmount)

          accountManager.subtractAmountFromAccount(tranObject)

        //Add amount to transferTo account
          accountManager.addAmountToAccount({ account: transferTo, amount: tranObject.amount })
      }

    }

  },

  getTransactionByName(name) {
    this.getTransactions()

    for (let i = 0; i < this.transactions.length; i++) {
      if (this.transactions[i].transactionName === name) {
        return this.transactions[i]
      }
    }

  },

  updateTransaction(oldTransactionData, newTransactionData) {
    if (this.isRollbackNeeded(newTransactionData)) {
      this.updateAccountWithChangedTransactionAmount(
        newTransactionData.account,
        newTransactionData.transferTo,
        JSON.parse(JSON.stringify(oldTransactionData.transactionBalances[0].transactionAmount)),
        JSON.parse(JSON.stringify(0)),
        newTransactionData.transactionType
      )
    }

    if (this.checkIfTransacationAmountNeedsAdjusting(oldTransactionData, newTransactionData)) {
      this.updateAccountWithChangedTransactionAmount(
        newTransactionData.account,
        newTransactionData.transferTo,
        parseFloat(oldTransactionData.transactionBalances[0].transactionAmount),
        parseFloat(newTransactionData.transactionBalances[0].transactionAmount),
        newTransactionData.transactionType
      )
    }

    let transaction = this.transferTransactionData(oldTransactionData, newTransactionData)

    this.updateTransactionInStore(transaction)
  },

  updateEditedTransaction(transaction) {
    let oldTransaction = this.getTransactionByName(transaction.transactionName)

    this.updateTransaction(oldTransaction, transaction)
  },

  isRollbackNeeded(transaction) {
    if (transaction.objectState === 'Delete') {
      return true
    }
    return false
  },

    checkIfTransacationAmountNeedsAdjusting(oldTransactionData, newTransactionData) {
        if (oldTransactionData.transactionBalances[0].transactionAmount !== newTransactionData.transactionBalances[0].transactionAmount) {
      return true
    }
    return false
  },

  updateTransactionInStore(transaction) {
    store.commit('updateTransactioninStore', transaction)
  },

  transferTransactionData(oldTransactionData, newTransactionData) {
    let transaction = this.transactionObject

    transaction.id = oldTransactionData.id
    transaction.account = newTransactionData.account
    transaction.budget = newTransactionData.budget
    transaction.category = newTransactionData.category
    transaction.comment = newTransactionData.comment
    transaction.currency = newTransactionData.currency
    transaction.cycle = newTransactionData.cycle
    transaction.goesOffFirst = newTransactionData.goesOffFirst
    transaction.items = newTransactionData.items
    transaction.numberOfCycles = newTransactionData.numberOfCycles

    if (newTransactionData.objectState === 'Delete') {
      transaction.objectState = newTransactionData.objectState
    }
    else {
      transaction.objectState = 'Update'
    }

    transaction.transactionBalances.push(this.transactionBalances);

    transaction.transactionBalances[0].transactionItems = newTransactionData.transactionBalances[0].transactionItems
    transaction.transactionBalances[0].paymentType = newTransactionData.transactionBalances[0].paymentType
    transaction.period = newTransactionData.period
    transaction.recurringTransaction = newTransactionData.recurringTransaction
    transaction.repeat = newTransactionData.repeat
    transaction.show = newTransactionData.show
    transaction.transactionBalances[0].transactionAmount = newTransactionData.transactionBalances[0].transactionAmount
    transaction.transactionBalances[0].transactionDate = newTransactionData.transactionBalances[0].transactionDate
    transaction.transactionName = newTransactionData.transactionName
    transaction.transactionBalances[0].transactionTime = newTransactionData.transactionBalances[0].transactionTime
    transaction.transactionType = newTransactionData.transactionType
    transaction.transferFrom = newTransactionData.transferFrom
    transaction.transferTo = newTransactionData.transferTo
    transaction.transactionBalances[0].changed = true

    return transaction
  },
  




}
